module.exports = [{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-userway/gatsby-browser.js'),
      options: {"plugins":[],"accountId":"BCMD1WqKzd"},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-locations/gatsby-browser.js'),
      options: {"plugins":[],"useStorage":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Anaba Japanese Cuisine Northshore","short_name":"Anaba Japanese Cuisine Northshore","start_url":"/","background_color":"#5E0A1A","theme_color":"#5E0A1A","display":"standalone","icon":"./src/favicon.png","icons":[{"src":"./src/favicon.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8051de703286e667ef88578eb64787e0"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.anabajapanese.com","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WZCFCDM9","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
